var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view view--elitepage view--mt-0" },
    [
      _vm.productPage
        ? [
            _c("header", { staticClass: "header" }, [
              _vm.productPage.header_bg_image_url
                ? _c("img", {
                    staticClass: "section__bgOverlay",
                    style: { opacity: _vm.productPage.header_dim_amount / 100 },
                    attrs: { src: _vm.productPage.header_bg_image_url },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "container",
                  class: { flexbox: _vm.productPage.header_content_image_url },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "block h-textCenter",
                      style: {
                        order:
                          _vm.productPage.header_content_image_position ===
                          "right"
                            ? 1
                            : 2,
                      },
                    },
                    [
                      _c("h1", { staticClass: "h1 h1--eliteTitles" }, [
                        _vm._v(_vm._s(_vm.productPage.header_content_title)),
                      ]),
                      _c("p", { staticClass: "p" }, [
                        _vm._v(
                          _vm._s(_vm.productPage.header_content_subtitle) + " "
                        ),
                      ]),
                      _vm.product
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn btn--primary",
                              attrs: {
                                to: {
                                  name: "ProductCheckout",
                                  params: { slug: _vm.producerSlug },
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Add To Cart $" + _vm._s(_vm.product.price)
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "block",
                      style: {
                        order:
                          _vm.productPage.header_content_image_position ===
                          "left"
                            ? 1
                            : 2,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.productPage.header_content_image_url,
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _c("section", { staticClass: "section section--sm" }, [
              _c(
                "div",
                {
                  staticClass: "container",
                  class: {
                    flexbox:
                      _vm.productPage.media_content_position !== "center" &&
                      (_vm.productPage.image_url ||
                        _vm.productPage.youtube_video_id),
                    "container--sm":
                      !_vm.productPage.image_url ||
                      !_vm.productPage.youtube_video_id,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        "h-textLeft":
                          _vm.productPage.media_content_position !== "center",
                        block:
                          _vm.productPage.media_content_position !== "center",
                      },
                      style: {
                        order:
                          _vm.productPage.media_content_position === "right"
                            ? 1
                            : 2,
                      },
                    },
                    [
                      _c("h1", { staticClass: "h1" }, [
                        _vm._v(_vm._s(_vm.productPage.text_title1)),
                      ]),
                      _c("p", { staticClass: "p textSizeMd" }, [
                        _vm._v(_vm._s(_vm.productPage.text_content1)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "block",
                      style: {
                        order:
                          _vm.productPage.media_content_position === "left"
                            ? 1
                            : 2,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flexbox flexbox--center flexWrap h-margin-40",
                        },
                        [
                          _vm.productPage.media_content_is_video &&
                          _vm.productPage.youtube_video_id
                            ? _c("iframe", {
                                attrs: {
                                  src:
                                    "https://www.youtube.com/embed/" +
                                    _vm.productPage.youtube_video_id,
                                  width: "560",
                                  height: "315",
                                  frameborder: "0",
                                },
                              })
                            : !_vm.productPage.media_content_is_video &&
                              _vm.productPage.image_url
                            ? _c("img", {
                                staticClass: "imgMediaCotent",
                                attrs: { src: _vm.productPage.image_url },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "container container--sm" },
                [
                  _c("h2", { staticClass: "h2" }, [
                    _vm._v(_vm._s(_vm.productPage.text_title2)),
                  ]),
                  _c("p", { staticClass: "p textSizeMd" }, [
                    _vm._v(_vm._s(_vm.productPage.text_content2)),
                  ]),
                  _vm.product
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn--lg btn--primary h-margin-40",
                          attrs: {
                            to: {
                              name: "ProductCheckout",
                              params: { slug: _vm.producerSlug },
                            },
                          },
                        },
                        [_vm._v("Add To Cart $" + _vm._s(_vm.product.price))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }